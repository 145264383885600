@import "../../assets/scss/variables";

// no funciona be amb totes les icones
// .icon {
//   path, circle {
//     stroke: $black;
//   }
// }

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
